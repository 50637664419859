<template>
  <!-- <a-page-header
    @back="goBack"
    title="CARGAR DATOS COMPLEMENTARIOS"
    :breadcrumb="{ routes }"
    class="px-0"
  /> -->

  <a-page-header
    @back="() => $router.go(-1)"
    title="CARGAR DATOS COMPLEMENTARIOS"
    class="pt-0 px-0"
  >
    <template #extra>
      <a-breadcrumb>
        <a-breadcrumb-item><router-link to="/menu">Import./Export.</router-link></a-breadcrumb-item>
        <a-breadcrumb-item>Cargar datos complementarios</a-breadcrumb-item>
      </a-breadcrumb>
    </template>
  </a-page-header>

  <div class="flex flex-wrap justify-between mb-8">
    <a-alert class="md:w-10/12">
      <template #description>
        <div class="mb-2">
          En esta ventana se suben los datos complementarios como correo, números de celular de los
          pacientes. Tener en cuenta que ya deben estar creadas las tablas en la base de datos.
        </div>

        <p>✅ Formatos válidos .csv y .zip</p>
        <p>✅ Se actualiza al último subido</p>
        <p>✅ Ejemplo de archivo: reg_paciente.csv</p>

        <p class="italic text-gray-400 my-2">
          Tener en cuenta que si dentro del archivo contiene fechas erróneas no se subirá y mostrará
          un error.
        </p>
      </template>
    </a-alert>
    <button @click="descargarPlantilla" type="button" class="text-center px-3 md:w-2/12">
      <div class="text-3xl text-green-600">
        <DownloadOutlined />
      </div>
      <p>
        Descargar <br />
        plantilla
      </p>
    </button>
  </div>

  <div class="lg:flex lg:gap-3">
    <a-upload-dragger
      v-model:fileList="fileList"
      accept=".csv,.zip"
      name="file"
      :multiple="true"
      :action="urlAction"
      :headers="headers"
      :before-upload="beforeUpload"
      @change="onChangeUpload"
    >
      <p class="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p class="ant-upload-text">Haz click o arrastra en este área para subir</p>
      <p class="ant-upload-hint p-8">
        Se aceptan csv (delimitado por comas) y zip. En zip se puede enviar más de 1 archivo.
      </p>
    </a-upload-dragger>

    <div class="mt-8 lg:m-0">
      <div class="overflow-x-auto w-full">
        <a-table
          :columns="columns"
          :data-source="data"
          size="small"
          :pagination="false"
          rowKey="idimportacion"
          :rowClassName="(record, index) => (index % 2 === 0 ? 'bg-white' : 'bg-gray-100')"
        >
          <template #rangepages="{ index }">
            <span> {{ (currentPage - 1) * 10 + index + 1 }} </span>
          </template>

          <template #estado="{ text: estado }">
            <!-- <span>
              <a-tag v-if="estado === '0'" color="red"> Error </a-tag>
              <p v-if="estado === '1'" class="flex text-xs">
                <a-spin size="small" />&nbsp;cargando
              </p>
              <a-tag v-if="estado === '2'" color="green"> Exitoso </a-tag>
            </span> -->
            <a-tag v-if="estado === '2'" color="success">
              <template #icon>
                <CheckCircleOutlined />
              </template>
              exitoso
            </a-tag>

            <a-tag v-if="estado === '1'" color="processing">
              <template #icon>
                <SyncOutlined :spin="true" />
              </template>
              procesando
            </a-tag>

            <a-tag v-if="estado === '0'" color="error">
              <template #icon>
                <CloseCircleOutlined />
              </template>
              error
            </a-tag>
          </template>
        </a-table>
      </div>
      <footer class="flex items-center justify-between pb-6 pt-5 border-t-2">
        <div class="flex">
          <a-pagination
            v-model:current="currentPage"
            v-model:pageSize="pageSize"
            :total="totalDocs"
          />
        </div>

        <p class="text-gray-300 font-semibold">Archivos cargados las últimas 24 horas</p>
      </footer>
    </div>
  </div>

  <CargaComplementariaForm v-if="isVisible" :isVisible="isVisible" @closeModal="closeModal" />
</template>

<script>
import { ref, watch, toRaw, onMounted } from "vue";
import router from "@/router";
import { message, notification } from "ant-design-vue";
import { useStore } from "vuex";
import { columns, breadcrumb } from "./utilsCargaComplementaria";
import CargaBaseDatosApi from "@/api/cargaBaseDatos";
import UsuariosApi from "@/api/user";
import CargaComplementariaForm from "./CargaComplementariaForm.vue";
import {
  InboxOutlined,
  DownloadOutlined,
  CheckCircleOutlined,
  SyncOutlined,
  CloseCircleOutlined
} from "@ant-design/icons-vue";
import { getUsuario } from "@/utils/auth";

export default {
  components: {
    InboxOutlined,
    DownloadOutlined,
    CheckCircleOutlined,
    CargaComplementariaForm,
    SyncOutlined,
    CloseCircleOutlined
  },
  data: function () {
    return {
      intervalid1: ""
    };
  },
  methods: {
    todo: function () {
      this.intervalid1 = setInterval(() => {
        this.fetchData();
      }, 3000);
    }
    // uploadfiles({  file }) {
    // this.upload(file)
    //   .then(() => {
    //     onSuccess(null, file);
    //   })
    //   .catch(() => {
    //     console.log('error');
    //   });
    // }
  },
  mounted: function () {
    this.todo();
  },
  unmounted: function () {
    clearInterval(this.intervalid1);
  },
  setup() {
    const pageSize = ref(10);
    const store = useStore();
    const data = ref([]);
    const currentPage = ref(1);
    const totalDocs = ref(null);
    const urlPlantilla = ref("");
    const isVisible = ref(false);
    const { establecimiento } = store.state.user.usuario;
    const urlAction = process.env.VUE_APP_UPLOAD_API + "/carga_complementaria";

    const obtieneEnlacePlantilla = () => {
      CargaBaseDatosApi.getEnlacePlantilla("PLANTILLA_COMPLEMENTARIO")
        .then((res) => {
          urlPlantilla.value = res.data[0].valor;
        })
        .catch((err) => console.log(err));
    };

    const beforeUpload = (file) => {
      const isZipOrCSV =
        file.type === "application/zip" ||
        file.type === "text/csv" ||
        file.type === "application/x-zip-compressed" ||
        file.type === "application/vnd.ms-excel";

      if (!isZipOrCSV) {
        notification.error({
          message: "Operación inválida",
          description: "Solo se aceptan archivos de tipo .zip y .csv"
        });
      }
      return isZipOrCSV;
    };

    const onChangeUpload = (info) => {
      const status = info.file.status;

      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }

      if (status === "done") {
        message.success(`${info.file.name} Archivo agregado correctamente.`);
      } else if (status === "error") {
        message.error(`${info.file.name} Falló el subir el archivo.`);
      }
    };

    const descargarPlantilla = () => {
      window.open(urlPlantilla.value, "_blank");
    };

    const fetchData = () => {
      // isLoading.value = true;
      CargaBaseDatosApi.getAll(toRaw(currentPage.value), getUsuario(), "2")
        .then((response) => {
          console.log(response);
          data.value = response.data.docs;
          totalDocs.value = response.data.totalDocs;
        })
        .catch((err) => console.log(err));
      // .finally(() => (isLoading.value = false));
    };

    watch(currentPage, () => {
      fetchData();
    });

    // ------- mostrar modal -------
    const showModal = () => {
      isVisible.value = true;
    };

    const closeModal = () => {
      isVisible.value = false;
    };

    onMounted(() => {
      UsuariosApi.userLogAdd("Import./Export.", "Carga Datos Complementarios", establecimiento);
      fetchData();
      obtieneEnlacePlantilla();
    });

    return {
      goBack: () => router.back(),
      fileList: ref([]),
      routes: breadcrumb,
      onChangeUpload,
      headers: {
        authorization: "Bearer " + store.getters.token
      },
      // dataList,
      data,
      // isLoading,
      fetchData,
      isVisible,
      currentPage,
      totalDocs,
      pageSize,
      descargarPlantilla,
      closeModal,
      showModal,
      columns,
      urlAction,
      beforeUpload
    };
  }
};
</script>

<style></style>
