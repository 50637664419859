<template>
  <a-modal
    :visible="true"
    title="Nueva Productora Servicio"
    @ok="handleOk"
    @cancel="handleCancel"
    @close="closeModal"
    class="max-w-sm"
    okText="Descargar"
  >
    <a-form :model="formState" layout="vertical">
      <a-form-item label="Tabla Base de Datos" name="tabla">
        <a-select
          ref="select"
          v-model:value="formState.tabla"
          show-search
          placeholder="Selecciona el tabla"
        >
          <a-select-option value="reg_paciente" key="reg_paciente" title="reg_paciente">
            maestro_paciente
          </a-select-option>
        </a-select>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { reactive } from "vue";
import DescargarPlantillaApi from "@/api/descargar_plantilla";

export default {
  emits: ["closeModal", "fetchProductoraServicios"],
  setup(props, { emit }) {
    // const formRef = ref();

    const formState = reactive({
      tabla: ""
    });

    const closeModal = () => {
      emit("closeModal");
    };

    const handleOk = () => {
      DescargarPlantillaApi.getPlantilla(formState.tabla);
    };

    const handleCancel = () => {
      emit("closeModal");
    };

    return {
      handleOk,
      closeModal,
      handleCancel,
      formState
    };
  }
};
</script>
