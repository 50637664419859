import { post } from "./base_descargaCSV";

const API_SERVICE_REPORTE_PLANTILLA = "/api/sivi_data/V1/descargar_plantilla";

export default {
  getPlantilla: (tabla) => {
    const request = {
      _type: 1,
      _table: tabla,
      _petitions: []
    };

    console.log(request);
    return post(API_SERVICE_REPORTE_PLANTILLA, request);
  }
};
